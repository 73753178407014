import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to Franky</h1>
        <p>The App That Simplifies Store-Customer Interaction</p>
      </header>

      <section className="App-section">
        <h2>What is Franky?</h2>
        <p>
          Franky is a location-based app that connects your store with nearby customers, helping you engage them without the need for delivery or individual store apps.
          With Franky, users explore your store’s offerings, discover promotions, and interact with your business—all in one place.
        </p>
      </section>

      <section className="App-section">
        <h2>Why Choose Franky?</h2>
        <ul>
          <li><strong>Seamless In-Place Interaction:</strong> Engage customers when they are near or inside your store with exclusive promotions, digital loyalty rewards, and real-time offers.</li>
          <li><strong>Reduced Costs:</strong> No hefty commissions like delivery apps. A low-cost, scalable platform to promote your business.</li>
          <li><strong>Control Over Engagement:</strong> Stay in control of how you interact with customers through customized promotions and loyalty programs.</li>
          <li><strong>Powerful Data Insights:</strong> Gain actionable insights into customer foot traffic and behavior.</li>
        </ul>
      </section>

      <section className="App-section">
        <h2>How Franky Works</h2>
        <ol>
          <li><strong>List Your Store:</strong> Simply add your store location to Franky’s platform.</li>
          <li><strong>Engage Customers in Real-Time:</strong> Offer real-time promotions and loyalty rewards, enhancing the in-store experience.</li>
          <li><strong>Grow Your Foot Traffic:</strong> Franky helps drive new customers into your store whenever they are nearby.</li>
        </ol>
      </section>

      <section className="App-section">
        <h2>Contact Us</h2>
        <p>Ready to take the next step? Let’s bring your store onto Franky and start driving more foot traffic today.</p>
        <p><strong>Email:</strong> support@itsfranky.com</p>
        <p><strong>Phone:</strong> +56 1234 567890</p>
        <p><strong>Location:</strong> Santiago, Chile</p>
      </section>
    </div>
  );
}

export default App;
